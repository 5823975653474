export function detectBrowserAndOS() {
    const userAgent = navigator.userAgent;
    // console.log('userAgent: ', userAgent);
    
    let browser = "Unknown";
    let os = "Unknown";
  
    // Detect browser - order matters for accurate detection
    // still not properly identifying the Opera on iOS, but all other
    // combinations of iOS/Android with Safari/Chrome/Opera/Edge/Firefox are right. 
    if (userAgent.indexOf("CriOS") > -1) {
        browser = "Chrome";  // Chrome on iOS
    } else if (userAgent.indexOf("FxiOS") > -1) {
        browser = "Firefox"; // Firefox on iOS
    } else if (userAgent.indexOf("OPiOS") > -1) {
        browser = "Opera";   // Opera on iOS
    } else if (userAgent.indexOf("EdgiOS") > -1) {
        browser = "Microsoft Edge (Chromium)"; // Edge on iOS
    } else if (userAgent.indexOf("Firefox") > -1) {
        browser = "Firefox";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
        browser = "Samsung Browser";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browser = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        browser = "Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
        browser = "Microsoft Edge (Legacy)";
    } else if (userAgent.indexOf("Edg") > -1) {
        browser = "Microsoft Edge (Chromium)";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Safari";
    }
  
    // Detect OS
    if (userAgent.indexOf("Win") > -1) {
        os = "Windows";
    } else if (userAgent.indexOf("Android") > -1) {
        os = "Android";
    } else if (userAgent.indexOf("iPhone") > -1 || 
               userAgent.indexOf("iPad") > -1 || 
               userAgent.indexOf("iPod") > -1||
               (userAgent.indexOf("Mac") > -1 && 
                navigator.maxTouchPoints > 0)) {
        os = "iOS";
    } else if (userAgent.indexOf("Mac") > -1) {
        os = "macOS";
    } else if (userAgent.indexOf("Linux") > -1) {
        os = "Linux";
    }
  
    return { browser, os };
  }
  
  // Example usage
  const { browser, os } = detectBrowserAndOS();
  console.log(`Browser: ${browser}, OS: ${os}`);