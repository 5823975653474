import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthorizedRoute } from "./components/AuthorizedRoute";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Loading } from "./components/Loading";
import { ENV, IS_LOCAL } from "./constants";
import {
  ERROR_404_URL,
  ERROR_500_URL,
  LOGIN_URL,
  PASSWORD_RESET_URL,
  ROOT_URL,
  STORY_EMBED_URL,
  STORYUSER_EMBED_URL,
  SCENE_INTERACT_URL,
  SCENEUSER_INTERACT_URL,
} from "./constants/urls";

import "./App.scss";
import "./css/interflexion.css";

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const PasswordReset = React.lazy(() => import("./views/Pages/PasswordReset/PasswordReset"));
// the embedded scene and story pages: 
const InteractPage = React.lazy(() => import("./views/Pages/EmbedScene"));
const EmbedPage = React.lazy(() => import("./views/Pages/EmbedStory"));

function App() {
  return (
    // console.log("App.tsx"),
    <ErrorBoundary>
      <BrowserRouter>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={LOGIN_URL} component={Login} />
            <Route exact path={PASSWORD_RESET_URL} component={PasswordReset} />
            <Route exact path={ERROR_404_URL} component={Page404} />
            <Route exact path={ERROR_500_URL} component={Page500} />
            <AuthorizedRoute path={ROOT_URL}>
              <Route exact path={STORY_EMBED_URL} component={EmbedPage} />
              <Route exact path={STORYUSER_EMBED_URL} component={EmbedPage} />
              <Route exact path={SCENE_INTERACT_URL} component={InteractPage} />
              <Route exact path={SCENEUSER_INTERACT_URL} component={InteractPage} />
              <DefaultLayout />
            </AuthorizedRoute>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
 
export default App;
