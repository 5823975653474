import React from "react";
import { Button } from "reactstrap";

export enum CONVERSATION_STAGE {
  INITIALIZING,
  STORY_OVERVIEW,
  INTERACTION_VIDEO,
  RESPONSE_VIDEO_RECORDING,
  ANALYSIS,
  EXPERT_VIDEO,
  STORY_PASSED,
  STORY_QUIT,
  ERROR,
}

export interface InteractionProps {
    sceneId: string;
    title: string;
    description: string;
    summary: string;
    interactionVideoUrl: string;
    expertVideoUrl: string;
    lastScene: boolean;
    userId: string;
    onConversationStageChange: (stage: CONVERSATION_STAGE | null) => void;
}

export interface ConversationProps {
  storyToAnalyze: string;
  startSceneId: string;
  interactUserId: string;
}
 
// ********************************************************************
// UX components 

 // TODO: use shared color
 export const headerColor = "#32afd9";

 export const Column = ({ children }: { children: React.ReactNode }) => {
   return (
     <div
       style={{
         width: "400px",
         overflowWrap: "break-word",
         wordWrap: "break-word",
         padding: "10px",
         minWidth: "200px",
         margin: "0 10px",
       }}
     >
       <div
         style={{
           maxWidth: "100%",    // Ensures content doesn't exceed column width
           height: "auto",      // Maintains aspect ratio for media
         }}
       >
         {children}
       </div>
     </div>
   );
 };
 
interface EmbedButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
}

export const EmbedButton = ({ onClick, children }: EmbedButtonProps) => (
  <Button
    style={{
      marginBottom: "10px",
      marginTop: "10px",
      color: "white",
      maxWidth: "400px",
    }}
    block
    color="info"
    onClick={() => {
      onClick?.();
    }}
  >
    {children}
  </Button>
);
