import React, { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import { INTERACT_USER_EMAIL, INTERACT_USER_PASSWORD } from "../../../constants/env";
import {
  STORY_EMBED_URL,
  STORYUSER_EMBED_URL,
} from "../../../constants/urls";
import { useAuth } from "../../../contexts/authUser";
import { matchInteractPath } from "../utils"; 
import EmbeddedConversation from "./conversation";

interface StoryAuthProps {
  userId: string;
  interactUserId: string;
  // assessmentMode: boolean;
}

const StoryPageWrapper = (props: StoryAuthProps) => {
  const interactUserId = props.interactUserId;

  const match = interactUserId === "" 
    ? matchPath<{ storyId: string; sceneId: string }>(
        window.location.pathname, 
        { path: STORY_EMBED_URL }
      )
    : matchPath<{ storyId: string; sceneId: string; userId: string }>(
        window.location.pathname,
        { path: STORYUSER_EMBED_URL, exact: true, strict: false }
      );
  const storyId = (match?.params.storyId || null);
  const sceneId = (match?.params.sceneId || null);
  // console.info("StoryPageWrapper about to call EmbeddedConversation with storyId, sceneId, interactUser: ", storyId, sceneId, interactUserId); // DEBUG

  if (storyId && sceneId) {
    return (
      <EmbeddedConversation
        storyToAnalyze={storyId}
        startSceneId={sceneId}
        interactUserId={interactUserId}
      />
    );
  }

  return <h5>Loading&hellip;</h5>;
};

const EmbedStory = () => {
    const auth = useAuth();
    const authUserId = auth.authUser?.uid || "";
    const [preventSignIn, setPreventSignIn] = useState(false);
  
    useEffect(() => {
      async function asyncLogIn() {
        await auth.signInWithEmailAndPassword(INTERACT_USER_EMAIL, INTERACT_USER_PASSWORD);
      }
      // TODO: remove when adding the new user role
      if (preventSignIn || !!auth.authUser) {
        // when exiting we call doSignOut, but right after this hook signs the user back in
        return;
      }
      asyncLogIn();
    }, [auth,preventSignIn]);
  
    const embedUidMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
      STORYUSER_EMBED_URL
    );
    const interactUserId = embedUidMatch?.params.userId || "";
    // console.info("embedStory interactUserId on URL is ",interactUserId); // DEBUG

    const contentBlock = auth.authUser ? (
      <StoryPageWrapper
      userId={authUserId}
      interactUserId={interactUserId || ""}
      />
    ) : (
      <h5>Authenticating...&hellip;</h5>
    );

  return (
    <div
    style={{
      position: "absolute", // More forceful positioning
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      overflowY: "scroll", // Force scrollbar even when not needed
      WebkitOverflowScrolling: "touch",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      border: "5px solid #bfefff",
      borderRadius: 10,
      padding: "20px",
      boxSizing: "border-box",
      }}
    >
      {contentBlock}
    </div>
  );
}
export default EmbedStory


/* old version: 
      style={{
        minHeight: "100vh",  // Changed from height to minHeight
        width: "100vw",
        overflowY: "auto",   // Changed from overflow: "scroll"
        display: "flex",
        flexDirection: "column", // Added this
        alignItems: "center",
        justifyContent: "flex-start", // Changed from "center"
        border: "5px solid #bfefff",
        borderRadius: 10,
        padding: "20px",
*/